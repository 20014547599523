<template>
  <b-row >
    <b-col cols="12" sm="6" md="4" lg="3"  v-for="promo,index in promotions" v-bind:key="index" class="d-flex">
        <b-card 
          :img-src="$http.defaults.baseURL + 'storage/app/private/' + promo.banner"
          img-alt="promoción ventanas"
          img-top
          no-body
        >
          <b-card-body>
            <b-card-title>{{promo.name_promotion}}</b-card-title>
            <b-card-text class="text-truncate text-truncate--2">{{promo.description}}</b-card-text>
          </b-card-body>
          <b-card-footer class="text-center">
            <b-button 
              @click="viewDetalle(index)"        
              variant="outline-primary"
              size="md"
              block
              class="mb-1">Ver</b-button>
            <p>
              <small class="text-muted">Válida hasta {{new Date(promo.final_date).toLocaleDateString('es-es',{ year: 'numeric',month: '2-digit',day: '2-digit',})}}</small>
            </p>
          </b-card-footer>
        </b-card>
    </b-col>
    <b-modal
      id="modal-detalle"
      ok-only
      ok-variant="primary"
      ok-title="Ok"
      modal-class="modal-primary"
      centered
      :title="'Empresa : '+ (promotion ? promotion.fabricante.toUpperCase() : '')"
    >
      <b-card-text>
        {{promotion ? promotion.description : ''}}
        <div v-if="link && link.length > 0">
          <hr>
          Archivo adjunto : <a target="_blank" :href="$http.defaults.baseURL + 'storage/app/private/'+link[0].download_link" >{{link[0].original_name}}</a>
        </div>
      </b-card-text>
    </b-modal>
  </b-row>
</template>
<script>
import { BRow,BCol,BCard,BCardText,BCardTitle,BCardFooter,BButton,BCardBody} from "bootstrap-vue";
export default {
  components: {BRow,BCol,BCard,BCardText,BCardTitle,BCardFooter,BCardBody,BButton},
  data() {
    return {
      promotions: [],
      promotion : 0,
      link : null
    };
  },
  mounted () {
    this.$http.get("/api/getPromos").then((response) => {
        this.promotions = response.data
      });
  },
  methods : {
    viewDetalle(index) {
      this.link = null;
      this.promotion = this.promotions[index]
      if(this.promotion.attach_file ){
        this.link = typeof this.promotion.attach_file != 'array' ? JSON.parse(this.promotion.attach_file) : this.promotion.attach_file 
      }
       this.$bvModal.show('modal-detalle') 
    }
  }
};
</script>
<style>
.text-truncate.text-truncate--2{
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}
</style>